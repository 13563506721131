<template>
    <div class="forgot-wrapper">
        <div class="row">
            <div class="col-md-4 offset-md-4">
                <div class="forgot-div">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-10 offset-md-1 text-center mb-3">
                                <h5>Forgot Password</h5>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Password" autocomplete="off" v-model="reset.password">
                                     <label class="validation-msg" v-if="errors.password">{{ errors.password.msg }}</label>
                                </div>
                            </div>
                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Confirm Password" autocomplete="off" v-model="reset.confirm_password">
                                     <label class="validation-msg" v-if="errors.confirm_password">{{ errors.confirm_password.msg }}</label>
                                </div>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <button class="btn btn-primary" @click="resetPassword">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            errors:{},
            reset:{},
            reset_token:this.$route.params.token,
        }
    },
    methods:{
        resetPassword(){
            this.errors = {}
            this.$axios.post('/api/users/reset-password',{
                password:this.reset.password,
                confirm_password:this.reset.confirm_password,
                token:this.reset_token
            })
            .then(response =>{
                this.$notify({
                    title: "Updated!",
                    text: "Password has been updated.",
                    type: "success",
                }).then(result=>{
                    this.$router.push('/login')
                });
            })
            .catch(errors =>{
                if(errors.response.status == 422){
                    this.errors = errors.response.data
                }
            })
        }
    }
}
</script>
<style scoped>
    .forgot-wrapper {
        min-height: 85vh;
        max-width: 99vw;
    }
    .forgot-div {
        color: #172B4D;
        margin-top: 25vh;
        padding: 15px;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: var(--ds-shadow-raised,0 2px 2px rgba(23,43,77,0.2),0 0 2px rgba(23,43,77,0.2));
    }
    .form-group .btn {
        width: 100%;
    }
    .div-forgot {
        margin-top: 5px;
        font-size: 15px;
    }
    .validation-msg {
        text-align: left;
        width: 100%;
        margin-bottom: 0px;
        font-size: 14px;
        color: #ff0049;
    }
</style>