<template>
    <div class="forgot-wrapper">
        <div class="row">
            <div class="col-md-4 offset-md-4">
                <div class="forgot-div">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-10 offset-md-1 text-center mb-3">
                                <h5>Forgot Password</h5>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Email" autocomplete="off" v-model="recover.email">
                                     <label class="validation-msg" v-if="errors.email">{{ errors.email.msg }}</label>
                                </div>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <button class="btn btn-primary" @click="forgotPassword">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    
    data(){
        return{
            recover:{
                email:''
            },
            errors:{}
        }
    },
    methods:{
        forgotPassword(){
            this.errors={}
            this.$axios.post('/api/users/reset-token', this.recover)
            .then(response=>{
                this.$notify({
                    title: "Sent!",
                    text: "Email with instructions to reset password will be sent to your email if it exists in our system",
                    type: "success",
                })
            })
            .catch(err => {
                if (err.response.status == 422) {
                    this.errors = err.response.data;
                }
            });
        }
    },
}
</script>

<style scoped>
    .forgot-wrapper {
        min-height: 85vh;
        max-width: 99vw;
    }
    .forgot-div {
        color: #172B4D;
        margin-top: 25vh;
        padding: 15px;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: var(--ds-shadow-raised,0 2px 2px rgba(23,43,77,0.2),0 0 2px rgba(23,43,77,0.2));
    }
    .form-group .btn {
        width: 100%;
    }
    .div-forgot {
        margin-top: 5px;
        font-size: 15px;
    }
    .validation-msg {
        text-align: left;
        width: 100%;
        margin-bottom: 0px;
        font-size: 14px;
        color: #ff0049;
    }
</style>