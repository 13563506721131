<template>
    <div class="login-wrapper">
        <div class="row">
            <div class="col-md-4 offset-md-4">
                <div class="login-div">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-10 offset-md-1 text-center mb-3">
                                <h5>Login Here</h5>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <input v-model="form.email" type="text" class="form-control checkout_control" placeholder="Email" autocomplete="off">
                                    <label class="validation-msg" v-if="errors.email">{{ errors.email.msg }}</label>
                                </div>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <input v-model="form.password" type="password" class="form-control checkout_control" placeholder="Password">
                                    <label class="validation-msg" v-if="errors.password">{{ errors.password.msg }}</label>
                                    <label class="validation-msg" v-if="errors.error">{{ errors.error.msg }}</label>
                                </div>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <a class="btn btn-primary" to="/" v-on:click.prevent="login">Login</a>
                                    <div class="div-forgot text-right">
                                        <router-link to="/forgot-password">Forgot Password?</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    email: '',
                    password: ''
                },
                errors: {}
            }
        },
        methods: {
            login() {
                this.errors = {};
                this.$axios.post('/api/users/login', this.form)
                .then(res => {
                    if (res.data.token) {
                        this.$store.commit('setToken', res.data.token);
                        this.$store.commit('setUser', res.data);
                        this.$router.push('/');
                    }
                }).catch(err => {
                    if (err.response.status == 422) {
                        this.errors = err.response.data;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .login-wrapper {
        min-height: 85vh;
        max-width: 99vw;
    }
    .login-div {
        color: #172B4D;
        margin-top: 25vh;
        padding: 15px;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: var(--ds-shadow-raised,0 2px 2px rgba(23,43,77,0.2),0 0 2px rgba(23,43,77,0.2));
    }
    .form-group .btn {
        width: 100%;
    }
    .div-forgot {
        margin-top: 5px;
        font-size: 15px;
    }
    .validation-msg {
        text-align: left;
        width: 100%;
        margin-bottom: 0px;
        font-size: 14px;
        color: #ff0049;
    }
    .alert-danger {
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 14px;
    }
</style>