<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h4>Bank Accounts</h4>
            </div>
            <div class="text-right offset-md-4 col-sm-2 mb-2">
                <button class="btn mr-1 mb-3 btn-primary refresh-btn" @click="createLinkToken">Add Account</button>
            </div>
        </div>
        <div class="row">
            <template v-if="banks.length > 0">
                <div class="col-md-4 banks mt-20" v-for="bank in banks" :key="bank.id">
                    <div class="delete-icon" @click="delete_bank(bank.id)">
                        <i class="fa fa-trash text-danger bank_dlt_icon"></i>
                        <span class="tooltiptext">Delete</span>
                    </div>
                    <div class="reactivate-icon" v-if="bank.re_activate == 1">
                        <i class="fa fa-refresh ml-3 text-primary bank_ractivate_icon"
                            @click="createUpdateLinkToken(bank.id)">
                        </i>
                        <span class="tooltiptext">Re-Authenticate</span>
                    </div>
                    <div class="stat" @click="popup = bank">
                        <div class="col-md-10">
                            <h5 class="stat-header">{{bank.institution_name}}</h5>
                        </div>
                        <h3 class="stat-count">{{bank.total_balance | currency}}</h3>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="col-md-12">No Accounts Found</div>
            </template>
            
        </div>
        <AccountsPopup v-if="popup" :bank="popup" v-on:modalClosed="popup = false"/>
        
    </div>
    
</template>
<script>
import Vue from 'vue';
import Swal from 'sweetalert2'
import vueNumeralFilterInstaller from 'vue-numeral-filter';

Vue.use(vueNumeralFilterInstaller, { locale: 'en-au' });
export default {
    components: { AccountsPopup:() => import('./AccountsPopup.vue') },
    data(){
        return{
            scriptUrl:'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
            banks:[],
            popup:false,
            plaidLinkProps:{
                token:null,
                onSuccess: this.handleOnSuccess
            },
            bankID:null
        }
    },
    props:['user_id'],
    mounted(){
        this.getAccount()
        let scriptEl = this.getScriptEl();
        const scriptAlreadyInserted = scriptEl != null;
        if (scriptAlreadyInserted) {
            return;
        }

        scriptEl = document.createElement('script');
        scriptEl.type = 'text/javascript';
        scriptEl.src = this.scriptUrl;
        document.head.appendChild(scriptEl);
    },
    methods:{
        getAccount(){
            this.$axios.get('/api/users/get-bank-accounts')
            .then(response =>{
                this.banks = response.data.banks,
                this.sendToParent()
            })
            .catch(err=>{})
        },
        sendToParent(){
            this.$emit('banks',this.banks)
        },
        createUpdateLinkToken(bank_id) {
            console.log(this.$props.user_id)
            this.bankID = bank_id
            this.$axios.post('/api/users/createUpdateLinkToken', { user_id: this.$props.user_id, bank_id: bank_id })
                .then(response => {
                    let token = response.data.link_token;
                    window.Plaid.create({
                        token: token,
                        onSuccess: this.handleAccUpdate,
                        onEvent: this.handleError
                    }).open();

                })
                .catch(errors => {
                    console.log(errors)
                })
        },
        createLinkToken(){
            this.$axios.post('/api/users/create-link-token',{user_id:this.user_id.toString()})
            .then(response=>{
                let token = response.data.link_token
                console.log(token)
                this.plaidLinkProps.token = token
                // this.plaidLinkHandler.open();
                this.addAccount()
            })
            .catch(errors=>{
                console.log(errors)
                // this.errorSwal()
            })
        },
        // handle if update acc is success
        handleAccUpdate(public_key, metadata) {
            // changing account data
            this.$axios.post('/api/users/changeAcc', {
                bank: this.bankID,
                public_key: public_key,
                metadata: metadata,
                user_id: this.$props.user_id
            }).then(response => {
                this.$notify({
                    text: 'Account updated',
                    type: 'success'
                })
                this.getAccount()
            }).catch(err => {
                console.log(err);
            })
        },
        handleError(event, metadata) {
            this.$notify({
                    text: 'Something went wrong',
                    type: 'error'
            })
        },
         addAccount(){
            window.Plaid.create({ ...this.plaidLinkProps }).open();
        },
         getScriptEl(){
            return document.querySelector(`script[src="${this.scriptUrl}"]`);
        },
        handleOnSuccess(public_key,metadata){
            console.log({public_key:public_key,metadata:metadata})
            this.$axios.post('/api/users/add-account',{
                public_key:public_key,
                metadata:metadata,
                user_id:this.user_id
            })
            .then(res=>{
                this.$notify({
                    text: 'Account added',
                    type: 'success'
                })
                this.getAccount()
            })
            .catch(err =>{
                alert('Error')
            })

        },
        delete_bank(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "You will not be able to recover this Bank details!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!",
                heightAuto: false
            }).then((result) => {
                if (result.value) {
                    this.$axios.post('/api/users/delete-bank', { id: id }).then(res => {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Bank details has been deleted.",
                            type: "success",
                            heightAuto: false
                        });
                        this.getAccount()
                    })
                }

            });
        }
    }
}
</script>
<style scoped>
.stat {
        padding: 15px 10px;
        border-radius: 3px;
        box-shadow: var(--ds-shadow-raised,0 1px 1px rgba(23,43,77,0.2),0 0 1px rgba(23,43,77,0.2));
    }
    .stat-header {
        text-align: left;
        font-size: 1rem;
        color: #5e5c5c;
        font-weight: 600;
    }
    .stat-count {
        text-align: left;
        font-size: 1.5rem;
        font-weight: 700;
        margin-left: 1rem;
    }
    .stat-desc {
        margin-top: 0;
        margin-bottom: .5rem;
        text-align: left;
        color: #5e5c5c;
    }
    .stat-desc span.green {
        color: #4caf50;
    }
    .stat-desc span.red {
        color: #dc3545;
        font-weight: 600;
    }
    .banks{
        cursor:pointer
    }
    .delete-icon {
        position: absolute;
        top: 6px;
        right: 30px;
    }
    .reactivate-icon{
        position: absolute;
        top: 6px;
        right: 50px;
    }
    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: #555;
        font-size: 12px;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        transform: translate(0%, 30px);
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
    }
    .bank_ractivate_icon:hover + .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
    .bank_dlt_icon:hover + .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
</style>