<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="stat">
                    <h5 class="stat-header">Bank Accounts</h5>
                    <h3 class="stat-count">{{bank_count}}</h3>
                    <!-- <p class="stat-desc"><span class="green">+2</span> last 28 days</p> -->
                </div>
            </div>
            <div class="col-md-4">
                <div class="stat">
                    <h5 class="stat-header">Total Account Balance</h5>
                    <h3 class="stat-count">{{total_balance | currency}}</h3>
                    <!-- <p class="stat-desc"><span class="green">+1,205.80</span> last 28 days</p> -->
                </div>
            </div>
            <!-- <div class="col-md-4">
                <div class="stat">
                    <h5 class="stat-header">Inactive Bank Accounts</h5>
                    <h3 class="stat-count">2</h3>
                    <p class="stat-desc"><span class="red">+1</span> last 28 days</p>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import vueNumeralFilterInstaller from 'vue-numeral-filter';

Vue.use(vueNumeralFilterInstaller, { locale: 'en-au' });
export default {
    data(){
        return {
            bank_count:null,
            total_balance:null,
        }
    },
    mounted(){
        this.$axios.get('/api/users/dashboard')
        .then(response =>{
            this.bank_count = response.data.bank_count
            this.total_balance = response.data.total_balance
        })
    }
}
</script>

<style scoped>
    .stat {
        padding: 15px 10px;
        border-radius: 3px;
        box-shadow: var(--ds-shadow-raised,0 1px 1px rgba(23,43,77,0.2),0 0 1px rgba(23,43,77,0.2));
    }
    .stat-header {
        text-align: left;
        font-size: 1rem;
        color: #5e5c5c;
        font-weight: 600;
    }
    .stat-count {
        text-align: left;
        font-size: 1.5rem;
        font-weight: 700;
        margin-left: 1rem;
    }
    .stat-desc {
        margin-top: 0;
        margin-bottom: .5rem;
        text-align: left;
        color: #5e5c5c;
    }
    .stat-desc span.green {
        color: #4caf50;
    }
    .stat-desc span.red {
        color: #dc3545;
        font-weight: 600;
    }
</style>