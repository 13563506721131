import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import axiosConfig from './lib/axios'
import Notifications from 'vue-notification'

Vue.config.productionTip = false
Vue.prototype.$axios = axios;

router.beforeEach((to, from, next) => {
    if(!store.state.token && !to.meta.auth) {
        next();
    } else if(store.state.token && !to.meta.auth) {
        router.push('/')
    } else if(!store.state.token && to.meta.auth) {
        router.push('/login')
    } else {
        next();
    }
});

// run axios interceptors
axiosConfig()

Vue.use(Notifications)

new Vue({
    router,
    store,
    axios,
    render: h => h(App)
}).$mount('#app');
