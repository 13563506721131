import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Blank from '../layouts/Blank.vue'
import LoggedOut from '../layouts/LoggedOut'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { auth: true }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { layout: LoggedOut }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: { layout: LoggedOut }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: { layout: LoggedOut }
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: ResetPassword,
        meta: { layout: LoggedOut }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
