<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <h4 class="trans">Transactions</h4>
            </div>
            <div class="text-right offset-md-4 col-sm-2 mb-2 refresh-parent">
                <button class="btn mr-1 mb-3 btn-primary refresh-btn" @click="getTransactions(0)"><em
                        class="fa fa-refresh"></em>Refresh</button>
            </div>
            <div class="col-12" v-if="reActivate">
                <div class="alert alert-danger text-center">The data may be wrong. Please re-authenticate to get latest data.</div>
            </div>
        </div>
        <div class="row mt-20 table-style">
            <div class="col-sm-3 mb-2">
                <!-- <label class="inline-block">From</label> -->
                <datepicker :bootstrapStyling="true" placeholder="From Date" :format="customFormatter(from,'From')"
                    v-model="from" @input="getTransactions(0)" class="inline-block" />
            </div>
            <div class="col-sm-3 mb-2">
                <!-- <label class="inline-block">To</label> -->
                <datepicker :bootstrapStyling="true" placeholder="To Date" :format="customFormatter(to,'To')"
                    v-model="to" @input="getTransactions(0)" class="inline-block" />
            </div>
            <div class="text-right col-md-3 mb-2">
                <select class="form-control" v-model="bankFilter" @change="getTransactions(1)">
                    <option :value="null">-- Select Bank --</option>
                    <option v-for="bank in banks" :key="`bnk_${bank.id}`" :value="bank.id">{{ bank.institution_name }}
                    </option>
                </select>
            </div>
            <div class="text-right  col-md-3 mb-2">
                <select class="form-control" v-model="accountFilter" @change="getTransactions(0)">
                    <option :value="null">-- Select Account --</option>
                    <option v-for="account in accounts" :key="`account_${account.id}`" :value="account.id">{{
                    account.name }}</option>
                </select>
            </div>

            <div class="col-md-12">
                <div class="table">
                    <vue-good-table theme="polar-bear" styleClass="vgt-table striped" :columns="columns" :rows="transactions"
                        :pagination-options="{
                            enabled: true,
                            mode: 'records',
                            perPage: 10,
                            position: 'bottom', 
                            perPageDropdownEnabled: false,
                            mode: 'pages'
                        }" />
                    <!--  <table class="table">
                        <thead>
                            <th>Sl</th>
                            <th>Date</th>
                            <th>Bank</th>
                            <th>Account Number</th>
                            <th>Account Name</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Category</th>
                        </thead>
                        <tbody>
                            <tr v-for="(transaction,index) in transactions" :key="transaction.id">
                                <td>{{index+1}}</td>
                                <td>{{transaction.date | moment}}</td>
                                <td>{{transaction.Bank.institution_name}}</td>
                                <td>{{transaction.Account.account_number}}</td>
                                <td>{{transaction.Account.name}}</td>
                                <td>{{transaction.name}}</td>
                                <td>{{transaction.amount | currency}}</td>
                                <td>{{transaction.category}}</td>
                            </tr>
                        </tbody>
                    </table> -->
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Vue from 'vue';
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VueGoodTablePlugin from 'vue-good-table';


// import the styles
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);

Vue.use(vueNumeralFilterInstaller, { locale: 'en-au' });
export default {
    components: {
        Datepicker
    },
    props: ['user_id'],
    data() {
        return {
            banks: [],
            accounts: [],
            transactions: [],
            bankFilter: null,
            accountFilter: null,
            columns: [
                { label: 'Date', field: this.getDate },
                { label: 'Bank', field: 'Bank.institution_name' },
                { label: 'Acc #', field: 'Account.account_number' },
                { label: 'Acc Name', field: 'Account.name' },
                { label: 'Description', field: 'name' },
                { label: 'Category', field: 'category' },
                { label: 'Amount', field: this.getAmount, tdClass:this.getAmountClass },
                { label: 'Balance', field: this.getBalance, hidden: true ,tdClass:this.getBalanceClass},

            ],
            from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
            reActivate:false,
            balance:0
        }
    },
    methods: {
        getTransactions(changeBank) {
            this.reActivate = false
            if (changeBank) {
                this.accountFilter = null
            }

            //show balance column if account dropdown is selected
            if(this.accountFilter !== null){
                this.$set( this.columns[ 7 ], 'hidden', false );
            }
            else{
                this.$set( this.columns[ 7 ], 'hidden', true );
            }

            let url = `/api/users/transactions/${this.from_date}/${this.to_date}`
            if (this.bankFilter) {
                url += `/${this.bankFilter}`
                console.log(this.accountFilter)
                if (this.accountFilter) {
                    url += `/${this.accountFilter}`
                }
            }
            else {
                this.accountFilter = null
            }
            this.$axios.get(url)
                .then((response) => {
                    this.banks = response.data.banks
                    for(let bank of this.banks){
                        if(bank.re_activate == 1){
                            this.reActivate = true
                        }
                    }
                    this.accounts = response.data.accounts
                    this.transactions = response.data.transactions
                    this.balance = response.data.balance
                }).catch(err => {

                })
        },
        refreshTransactions() {
            this.$axios.post('api/users/refresh-transactions', { user_id: this.user_id })
                .then(response => {
                    this.bankFilter = null
                    this.$notify({
                        text: 'Refresh Completed',
                        type: 'success'
                    })

                    this.getTransactions(0)
                })
                .catch(error => {

                })
        },
        getDate(obj) {
            return this.$options.filters.moment(obj.date);
        },
        getAmount(obj) {
            return this.$options.filters.currency(0-obj.amount);
        },
        getAmountClass(obj){
            return (obj.amount < 0) ? 'text-success' : 'text-danger'
        },
        getBalance(obj){
            return this.$options.filters.currency(this.remainingBalance[obj.originalIndex])
        },
        getBalanceClass(obj){
            return (this.remainingBalance[obj.originalIndex] < 0) ? 'text-danger' : 'text-success'
        },
        customFormatter(date, string) {
            return string + ' : ' + moment(date).format('MMM DD, YYYY');
        }
    },
    mounted() {
        this.getTransactions()
    },
    filters: {
        moment: function (date) {
            return moment(date).format('MMM DD, YYYY');
        },
    },
    computed: {
        from_date() {
            return moment(this.from).format('YYYY-MM-DD').toString()
        },
        to_date() {
            return moment(this.to).format('YYYY-MM-DD').toString()
        },
        remainingBalance () {
            var tempBalance = this.balance
            return this.transactions.map((transaction,index) => {
                if(index == 0){
                    return tempBalance
                }
                else{
                    return tempBalance += this.transactions[index-1].amount
                }
            })
        
        }
    }
}
</script>
<style>
.vgt-table thead th,
.vgt-wrap__footer {
    background: #ECF5FD;
}

.fa-refresh {
    margin-right: 10px
}

.vgt-table,
.footer__navigation__page-info span,
.footer__navigation__page-btn span {
    font-size: 0.875rem !important;
    color:#656565 !important;
}

.refresh-btn, .trans, .refresh-parent {
    margin-bottom: 0px !important;
}

.vgt-table.polar-bear {
    border-collapse :collapse !important
}
.mt-20{
    margin-top:0.5rem !important;
}
</style>