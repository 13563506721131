<template>
    <div>
        <header>
            <Header />
        </header>
        <div class="div-fill">
            <router-view/>
        </div>
        <Footer />
    </div>
</template>

<script>

import Header from "../components/HeaderLoggedOut.vue";
import Footer from "../components/Footer.vue"

export default {
    components: {
        Header,
        Footer
    }
}
</script>

<style scoped>
    .div-fill {
        min-height: 85vh;
    }
</style>

