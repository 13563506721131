<template>
    <div class="">
        <nav id="" class="navbar navbar-expand-sm headermain">
            <router-link class="navbar-brand" to="/"><img src="/logo.png" alt="Franklin Capital" /><br></router-link>
            <button class="navbar-toggler" v-on:click.prevent="navbar_toggled = !navbar_toggled" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end" v-bind:class="{ show: navbar_toggled }" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                     <li class="nav-item active">
                        <router-link class="nav-link" to="/login">Login <span class="sr-only">(current)</span></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/register">Register</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/forgot-password">Forgot Password</router-link>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                navbar_toggled: false
            }   
        },
        methods: {
        },
        mounted() {
            
        }
    }
</script>