<template>
    <div>
        <h5 class="dashboardhead">My Profile</h5>
        <div class="row acdetails">
            <div class="col-lg-4 col-xs-12 pb-3">
                <label>First Name</label>
                <input type="text" class="form-control checkout_control" v-on:change="updateAccountDetails" v-model="form.first_name">
                <div class="label-invalid" v-if="validation.first_name">{{ validation.first_name.msg }}</div>
            </div>
            <div class="col-lg-4 col-xs-12 pb-3">
                <label>Last Name</label>
                <input type="text" class="form-control checkout_control" v-on:change="updateAccountDetails" v-model="form.last_name">
                <div class="label-invalid" v-if="validation.last_name">{{ validation.last_name.msg }}</div>
            </div>
            <div class="col-lg-8 col-xs-12 pb-3">
                <label>Email Address</label>
                <input type="text" class="form-control checkout_control" v-on:change="updateAccountDetails" v-model="form.email">
                <div class="label-invalid" v-if="validation.email">{{ validation.email.msg }}</div>
            </div>
        </div>
        <div class="acdetails no-gutters">
            <div class="text-bold pb-3 pt-3">Password Change</div>
            <div class="col-lg-4 col-xs-12 pb-3 ">
                <label>Current Password</label>
                <input type="password" class="form-control checkout_control" v-model="form.current_password">
                <div class="label-invalid" v-if="validation.current_password">{{ validation.current_password.msg }}</div>
            </div>
            <div class="col-lg-4 col-xs-12 pb-3 ">
                <label>New Password</label>
                <input type="password" class="form-control checkout_control" v-model="form.new_password">
                <div class="label-invalid" v-if="validation.new_password">{{ validation.new_password.msg }}</div>
            </div>
            <div class="col-lg-4 col-xs-12 pb-3 ">
                <label>Confirm Password</label>
                <input type="password" class="form-control checkout_control" v-model="form.confirm_password">
                <div class="label-invalid" v-if="validation.confirm_password">{{ validation.confirm_password.msg }}</div>
            </div>
            <div class="col-lg-4 col-xs-12 pb-3 ">
                <a href="#" class="searchrecords" v-on:click.prevent="changePassword">Confirm New Password</a>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '../store/index'

    export default {
        data() {
            return {
                form: {
                    first_name: store.state.first_name ? store.state.first_name : '',
                    last_name: store.state.last_name ? store.state.last_name : '',
                    email: store.state.email ? store.state.email : '',
                    current_password: '',
                    new_password: '',
                    confirm_password: ''
                },
                validation: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    current_password: '',
                    new_password: '',
                    confirm_password: ''
                }
            }
        },
        methods: {
            updateAccountDetails() {
                this.$axios.post('/api/users/update-account', {
                    first_name: this.form.first_name,
                    last_name: this.form.last_name,
                    email: this.form.email,
                }).then(res => {
                    this.$store.commit('setUser', res.data);
                    this.validation = {}
                    this.$notify({
                        text: 'Profile detail updated',
                        type: 'success'
                    })
                }).catch(err => {
                    if (err.response.status == 422) {
                        this.validation = err.response.data;
                    }
                })
            },
            changePassword() {
                this.$axios.post('/api/users/change-password', {
                    current_password: this.form.current_password,
                    new_password: this.form.new_password,
                    confirm_password: this.form.confirm_password,
                }).then(res => {
                    this.validation = {}
                    this.$notify({
                        text: 'Password updated successfully.',
                        type: 'success'
                    })
                }).catch(err => {
                    if (err.response.status == 422) {
                        this.validation = err.response.data;
                    }
                })
            }
        }
    }
</script>
