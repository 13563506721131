<template>
    <div class="signup-wrapper">
        <div class="row">
            <div class="col-md-4 offset-md-4">
                <div class="signup-div">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-10 offset-md-1 text-center mb-3">
                                <h5>Signup</h5>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center" v-if="success">
                                <div class="alert alert-success">Signup successfull, <router-link to="/login">Login</router-link></div>
                            </div>
                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <input type="text" v-model="form.company" class="form-control checkout_control" placeholder="Company" autocomplete="off">
                                    <label class="validation-error" v-if="validation.company">{{ validation.company.msg }}</label>
                                </div>
                            </div>
                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <input type="text" v-model="form.first_name" class="form-control checkout_control" placeholder="First Name" autocomplete="off">
                                    <label class="validation-error" v-if="validation.first_name">{{ validation.first_name.msg }}</label>
                                </div>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <input type="text" v-model="form.last_name" class="form-control checkout_control" placeholder="Last Name" autocomplete="off">
                                    <label class="validation-error" v-if="validation.last_name">{{ validation.last_name.msg }}</label>
                                </div>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <input type="text" v-model="form.email" class="form-control checkout_control" placeholder="E-mail Address" autocomplete="off">
                                    <label class="validation-error" v-if="validation.email">{{ validation.email.msg }}</label>
                                </div>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <input type="text" v-model="form.phone" class="form-control checkout_control" placeholder="Phone" autocomplete="off">
                                    <label class="validation-error" v-if="validation.phone">{{ validation.phone.msg }}</label>
                                </div>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <input type="password" v-model="form.password" class="form-control checkout_control" placeholder="Password">
                                    <label class="validation-error" v-if="validation.password">{{ validation.password.msg }}</label>
                                </div>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <input type="password" v-model="form.confirm_password" class="form-control checkout_control" placeholder="Confirm Password">
                                    <label class="validation-error" v-if="validation.confirm_password">{{ validation.confirm_password.msg }}</label>
                                </div>
                            </div>

                            <div class="col-md-8 offset-md-2 text-center">
                                <div class="form-group">
                                    <button class="btn btn-primary" v-on:click="submit">Signup</button>
                                    <div class="div-forgot text-right">
                                        <router-link to="/login">Already a user?</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    company:'',
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    password: '',
                    confirm_password: ''
                },
                validation: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    password: '',
                    confirm_password: ''
                },
                success: false
            }
        },
        methods: {
            submit() {
                this.success = false;
                this.$axios.post('/api/users/register', this.form).then(res => {
                    this.success = true;
                    this.validation = {}
                }).catch(err => {
                    if (err.response.status == 422) {
                        this.validation = err.response.data;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .signup-wrapper {
        min-height: 85vh;
        max-width: 99vw;
    }
    .signup-div {
        color: #172B4D;
        margin-top: 13vh;
        padding: 15px;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: var(--ds-shadow-raised,0 2px 2px rgba(23,43,77,0.2),0 0 2px rgba(23,43,77,0.2));
    }
    .form-group .btn {
        width: 100%;
    }
    .div-forgot {
        margin-top: 5px;
        font-size: 15px;
    }
    .validation-error {
        font-size: 14px;
        width: 100%;
        text-align: left;
        color: #ff0049;
        margin-bottom: 0px;
    }
    .alert-success {
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 14px;
    }
</style>