import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

import router from '../router/index.js';

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: window.localStorage
        }),
    ],
    state: {
        token: false,
        loading:false,
    },
    getters: {
    },
    mutations: {
        setToken(state, payload) {
			state.token = payload;
		},
        setUser(state, payload) {
			state.email = payload.email ? payload.email : false;
			state.first_name = payload.first_name ? payload.first_name : false;
			state.last_name = payload.last_name ? payload.last_name : false;
		},
        setLoader(state, payload){
            state.loading = payload;
        }
    },
    actions: {
        logoutUser(state) {
            state.commit('setToken', false);
            state.commit('setUser', {});
            router.push('/login');
        }
    },
    modules: {
    }
})
