<template>
    <div>
        <header>
            <Header />
        </header>
        <div class="div-fill">
            <router-view/>
        </div>
        <Footer />
        <div class="overlay" v-if="$store.state.loading">
            <div class="loader">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue"

export default {
    components: {
        Header,
        Footer
    }
}
</script>

<style scoped>
    .div-fill {
        min-height: 85vh;
    }
     .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ffffff99;
        z-index: 2000;
        cursor: pointer;
    }
    .loader {
        position: absolute;
        width: 100%;
        height: 100%;
        top:40%;
        left:49%
    }
    .spinner-border{
        width:4rem;
        height:4rem;
    }
</style>

