<template>
    <div id="app">
        <component :is="this.$route.meta.layout || 'Default'">
        </component>
        <notifications position="top center" classes="vue-notification" />
    </div>
</template>

<script>
import Default from "./layouts/Default.vue";

export default {
    components: {
        Default
    }
}
</script>

<style>
  @import './assets/css/bootstrap.css';
  @import './assets/css/main.css';

  .vue-notification-group {
      margin-top: 10px;
  }
</style>

