import store from '../store/index'
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL

export default function setup() {
    axios.interceptors.request.use(
        config => {
            store.commit('setLoader',true)
            const token = store.state.token
            if(token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config
        },
        error => {
            store.commit('setLoader',true)
            Promise.reject(error)
        }
    )
    axios.interceptors.response.use((response) =>{
        store.commit('setLoader', false)
        return response
        } 
        ,(error) => {

        store.commit('setLoader', false)
        if (error.response.status === 401) {
            store.dispatch('logoutUser')
        }
        return Promise.reject(error);
    });
    }