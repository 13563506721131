<template>
    <div class="">
        <nav id="" class="navbar navbar-expand-sm headermain">
            <router-link class="navbar-brand" to="/"><img src="/logo.png" alt="Franklin Capital" /><br></router-link>
            <button class="navbar-toggler" v-on:click.prevent="navbar_toggled = !navbar_toggled" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end" v-bind:class="{ show: navbar_toggled }" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item active">
                        <a @click="logout" class="nav-link">LOGOUT</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                navbar_toggled: false
            }   
        },
        methods: {
            logout() {
                this.$store.dispatch('logoutUser');
            }
        },
        mounted() {
            
        }
    }
</script>

<style scoped>
    .nav-link {
        cursor: pointer;
    }
</style>