<template>
<div>
    <div class="contentholder mt-4 mb-4">
        <div class="ml-4 mr-4 dashboardmain">
            <h1 class="pt-5 pb-1 text-left my_account">My Account</h1>
            <div class="row">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-3 col-lg-2 px-0 h-100 bg-white sidebar" id="sidebar">
                            <div class="list-group rounded-0 pt-4">
                                <a href="#/dashboard" class="list-group-item list-group-item-action border-0 d-flex align-items-center" v-bind:class="{ active: tab.dashboard }" v-on:click="tab_click('dashboard')" id="dashboard" data-toggle="tab" role="tab" aria-selected="true">
                                    <span class="bi bi-border-all"></span>
                                    <span class="ml-2">Dashboard</span>
                                </a>
                                <a href="#/my_profile" class="list-group-item list-group-item-action border-0 align-items-center" v-bind:class="{ active: tab.myProfile }" v-on:click="tab_click('my_profile')" id="my-profile" data-toggle="tab" role="tab" aria-selected="true">
                                    <span class="bi bi-box"></span>
                                    <span class="ml-2">My Profile</span>
                                </a>
                                <a href="#/bank_accounts" class="list-group-item list-group-item-action border-0 align-items-center" v-bind:class="{ active: tab.bankAccounts }" v-on:click="tab_click('bank_accounts')" id="bank-accounts" data-toggle="tab" role="tab" aria-selected="true">
                                    <span class="bi bi-box"></span>
                                    <span class="ml-2 icon_container">Bank Accounts 
                                        <i class="fa fa-exclamation-triangle text-danger warning_icon" v-if="this.reActivate"></i>
                                        <span class="sidebar_tooltiptext">Need to Re-Authenticate the bank account</span>                
                                    </span>
                                </a>
                                <a href="#/transactions" class="list-group-item list-group-item-action border-0 align-items-center" v-bind:class="{ active: tab.transactions }" v-on:click="tab_click('transactions')" id="transactions" data-toggle="tab" role="tab" aria-selected="true">
                                    <span class="bi bi-box"></span>
                                    <span class="ml-2">Transactions</span>
                                </a>
                            </div>
                        </div>
                        <div class="w-100 vh-100  overlay d-none" id="sidebar-overlay"></div>
                        <div class="col-md-7 col-lg-10 ml-md-auto px-0">
                            <main class="container-fluid">
                                <div class=" jumbotron-fluid rounded bg-white border-0 border-left pt-4 fluid-parent">
                                    <div>
                                        <div id="nav-myaccount">
                                            <div class="tab-pane fade" v-bind:class="{ show: tab.dashboard, active: tab.dashboard }" id="dashboard" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <Dashboard v-show="tab.dashboard" />
                                            </div>
                                            <div class="tab-pane fade" v-bind:class="{ show: tab.myProfile, active: tab.myProfile }" id="my-profile" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <MyProfile v-show="tab.myProfile" />
                                            </div>
                                            <div class="tab-pane fade" v-bind:class="{ show: tab.bankAccounts, active: tab.bankAccounts }" id="bank-accounts" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <BankAccounts v-show="tab.bankAccounts" :user_id="user_id" @banks="banksFromComponent"/>
                                            </div>
                                            <div class="tab-pane fade" v-bind:class="{ show: tab.transactions, active: tab.transactions }" id="transactions" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <Transactions v-show="tab.transactions" :user_id="user_id"/>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import Dashboard from '../components/Dashboard.vue'
import BankAccounts from '../components/BankAccounts.vue'
import Transactions from '../components/Transactions.vue'
import MyProfile from '../components/MyProfile.vue'

export default {

    data() {
        return {
            tab: {
                dashboard: true,
                bankAccounts: false,
                transactions: false,
                myProfile: false,
            },
            user_id:null,
            banks:[],
            reActivate:false
        }
    },
    components: {
        Dashboard,
        BankAccounts,
        Transactions,
        MyProfile
    },
    mounted() {
        if (window.location.hash) {
            this.tab_click(window.location.hash.substring(1).replace('/',''));
        }
        this.$axios.get('api/users/id')
        .then(response =>{
            this.user_id = response.data.uid
        })
    },
    methods: {
        tab_click: function (tab) {
            if (tab == 'dashboard') {
                this.tab.dashboard = true;
                this.tab.bankAccounts = this.tab.transactions = this.tab.myProfile = false;
            }
            if (tab == 'bank_accounts') {
                this.tab.bankAccounts = true;
                this.tab.dashboard = this.tab.transactions = this.tab.myProfile = false;
            }
            if (tab == 'transactions') {
                this.tab.transactions = true;
                this.tab.dashboard = this.tab.bankAccounts = this.tab.myProfile = false;
            }
            if (tab == 'my_profile') {
                this.tab.myProfile = true;
                this.tab.dashboard = this.tab.bankAccounts = this.tab.transactions = false;
            }
            
        },
        banksFromComponent : async function(data){
            this.banks = data
            // checking user bank data for re authentication
            let activate_check = await this.banks.filter(data => data.re_activate == 1);
            activate_check.length != 0 ? this.reActivate = true: this.reActivate = false;
        }
    }
}
</script>
<style>
    .my_account {
        padding-top : 0rem !important
    }
    .contentholder{
       width : 100% !important
    }
    .fluid-parent{
        padding-top:0rem !important
    }
    .icon_container{
        position: relative;
    }
    .sidebar_tooltiptext {
        visibility: hidden;
        width: 130px;
        background-color: #555;
        color: #fff;
        text-transform: lowercase;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        transform: translate(90%, 25px);
        left: 50%;
        opacity: 0;
        margin-left: -60px;
        transition: opacity 0.3s;
    }
    .sidebar_tooltiptext:first-letter {
        text-transform: uppercase;
    }
    .warning_icon:hover + .sidebar_tooltiptext{
        visibility: visible;
        opacity: 1;
    }
</style>
